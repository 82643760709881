import MyAccountModal from "@components/myAccountModal"
import TranslateWrapper from "@components/TranslateWrapper"
import ForgotPassword from "@modules/MyAccount/ForgotPassword"
import React, { useEffect, useState } from "react"

const ForgotPasswordPage = () => {
  const [visible, setVisible] = useState(true)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClose = () => {
    setVisible(false)
  }

  if (!isClient) return null

  return (
    <MyAccountModal
      visible={visible}
      handleClose={handleClose}
      className="mc forgotPassword"
    >
      <TranslateWrapper>
        <ForgotPassword />
      </TranslateWrapper>
    </MyAccountModal>
  )
}

export default ForgotPasswordPage
